/**
* Generated automatically at built-time (2024-08-30T15:31:09.781Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "ulkokeittio-suojus-custom-made",templateKey: "sites/106-11de724d-9c0e-4527-b598-9a4768d1afbc"};