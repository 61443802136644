/**
* Generated automatically at built-time (2024-08-30T15:31:09.823Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "hoito-opas-ulkokeittio",templateKey: "sites/106-9d316d76-2a3b-46d4-ae0b-229cafe1e991"};